<template>
  <div class="container" id="detail">
    <h1 class="hidden-pad-down mt_12 mb_6">
      <img src="../../assets/占星小鋪/titleIcon.svg" alt="" />
      詳細資訊
    </h1>
    <div class="detail_area mt-2" v-if="productDetail">
      <!-- 商品資訊區 -->
      <div class="info_area">
        <div class="info_area_title mb-6">{{ productDetail.Name }}</div>
        <div class="info_area_content" v-html="productDetail.Description"></div>
        <div class="info_area_otherInfo mb-12">
          <h3 class="mb-2">適用</h3>
          <p v-html="productDetail.Appliance"></p>
        </div>
        <div class="info_area_otherInfo mb-12">
          <h3 class="mb-2">限制</h3>
          <p v-html="productDetail.Restriction"></p>
        </div>
      </div>
      <!-- 中間圖片區 -->
      <div class="pic_area">
        <img :src="productDetail.ImageSrc" alt="" />
      </div>
      <!-- 購買與注意事項區 -->
      <div class="buy_area">
        <!-- 選擇自用或送禮 -->
        <div class="buy_area__useType">
          <div class="buy_area__useType__switch">
            <label for="personal" :class="{ active: useType == 'personal' }">
              <input
                type="radio"
                name="useType"
                id="personal"
                value="personal"
                v-model="useType"
              />
              <span>自用</span>
            </label>
            <label for="gift" :class="{ active: useType == 'gift' }">
              <input
                type="radio"
                name="useType"
                id="gift"
                value="gift"
                v-model="useType"
              />
              <span>送禮</span>
            </label>
          </div>
        </div>
        <div
          class="buy_area__sectionTitle mt_18 mb_10"
          v-if="useType == 'gift'"
        >
          <img src="@/assets/占星小鋪/gift-icon.png" alt="" />
          送禮方式
        </div>
        <!-- 選擇送禮模式 -->
        <div class="buy_area__giftMode" v-if="useType == 'gift'">
          <div class="buy_area__giftMode__radioGruop">
            <label for="memberId">
              <input
                type="radio"
                name="giftMode"
                id="memberId"
                value="memberId"
                v-model="giftMode"
              />
              <span>會員 ID</span>
            </label>
            <label for="code">
              <input
                type="radio"
                name="giftMode"
                id="code"
                value="code"
                v-model="giftMode"
              />
              <span>兌換碼</span>
            </label>
          </div>
          <div class="buy_area__giftMode__notice">
            <span v-show="giftMode == 'memberId'"
              >贈送禮物到對方的禮物盒。</span
            >
            <span v-show="giftMode == 'code'"
              >以兌換碼方式贈送禮物給收禮者。</span
            >
          </div>
        </div>
        <!-- 輸入送禮對象 ID -->
        <div
          class="buy_area__receiverMemberId"
          :class="{ onError: memberIdErrorMsg }"
          v-if="useType == 'gift' && giftMode == 'memberId'"
        >
          <div class="buy_area__receiverMemberId__form">
            <input
              type="text"
              id="receiverMemberId"
              v-model.trim="receiverMemberId"
              @input="memberIdErrorMsg = ''"
              :disabled="isReceiverMemberIdCheak"
              placeholder="請輸入對方會員 ID"
            />
            <div
              class="buy_area__receiverMemberId__form__checkBtn"
              :class="{ notReady: isReceiverMemberIdCheak }"
              @click="checkRevicerMemberId()"
            >
              確認
            </div>
          </div>

          <div class="buy_area__receiverMemberId__errorMsg">
            {{ memberIdErrorMsg }}
          </div>
          <div
            class="buy_area__receiverMemberId__successMsg"
            v-if="isReceiverMemberIdCheak"
          >
            將贈送禮物給ID: {{ receiverMemberId }}
          </div>
        </div>

        <!-- 內容 -->
        <div class="buy_area__sectionTitle mt_18 mb_2" v-if="useType == 'gift'">
          <img src="@/assets/占星小鋪/gift-icon.png" alt="" />
          禮物內容
        </div>
        <div class="buy_area_price" :class="{ isGift: useType == 'gift' }">
          <div class="price">
            <span>NT.</span>
            <span>{{ productDetail.Amount * butQuantity }}</span>
            <span>/組</span>
          </div>
          <div class="payMethods">
            <img src="../../assets/占星小鋪/cc-visa.svg" alt="" />
            <img src="../../assets/占星小鋪/master.svg" alt="" />
            <img src="../../assets/占星小鋪/Vector.svg" alt="" />
          </div>
        </div>

        <!-- 選擇數量 -->
        <div class="buy_area__quantity" v-if="needShowQuantityControl">
          <div
            class="buy_area__quantity__arrow decrease"
            @click="quantityAdd('dec')"
          ></div>
          <div class="buy_area__quantity__content">
            <div class="buy_area__quantity__content__number">
              <input
                type="number"
                :min="1"
                :max="999"
                :value="butQuantity"
                @input="quantityHandler"
                @blur="butQuantity < 1 ? (butQuantity = 1) : butQuantity"
              />
            </div>
          </div>
          <div
            class="buy_area__quantity__arrow increase"
            @click="quantityAdd('add')"
          ></div>
          <div class="buy_area__quantity__unit">組</div>
        </div>

        <!-- 購買按鈕 -->
        <div
          class="buy_area_btn"
          @click="toBuy()"
          :class="{ notReady: isSaleOut }"
        >
          {{ isSaleOut ? "已購買" : "立即購買" }}
        </div>
        <div class="buy_area_notify">
          <div class="buy_area_notify_title">
            <div><span></span></div>
            <h3>注意事項</h3>
          </div>
          <div
            class="buy_area_notify_content"
            v-html="productDetail.Notice"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "store-detail",
  data() {
    return {
      productDetail: {},
      butQuantity: 1,
      useType: "personal", //gift
      giftMode: "memberId", //code
      receiverMemberId: "",
      isReceiverMemberIdCheak: false,
      memberIdErrorMsg: "",
    };
  },
  async created() {
    this.$store.dispatch("setLoading", true);
    await this.getProductDetail();
    setTimeout(() => {
      this.$store.dispatch("setLoading", false);
    }, 500);
    //     if (moment().isAfter("2023-06-27 06:30")) return;
    //     this.$messagePopup(
    //       `【藍新金流系統通知】`,
    //       `6/27(二) 01:00 AM ~ 06:30 AM<br>銀行信用卡系統維護提醒，請勿在維護期間付款，避免造成服務解鎖失敗！<br>
    // 詳情請參閱: <a target="_blank" href="https://www.newebpay.com/info/news/news_detail?post_data=63412d677da3bccca49a09bba5857614344d263cafbd5c107aa36e732ea8d851">藍新金流服務公告</a>`,
    //       "了解",
    //       () => {}
    //     );
  },
  computed: {
    productList() {
      return this.$store.state.productList;
    },
    isSaleOut() {
      let slefHaveBuy =
        (this.productDetail.Code == "TransitChart" &&
          this.$store.state.userData.IsUnlockLC) ||
        (this.productDetail.Code == "MoonNote" &&
          this.$store.state.userData.EnabledMoonNote);

      return slefHaveBuy && this.useType === "personal";
    },
    needShowQuantityControl() {
      return (
        this.useType === "gift" &&
        ((this.giftMode === "memberId" &&
          ["HouseInterpretation", "Synastry"].includes(
            this.productDetail.Code
          )) ||
          this.giftMode === "code")
      );
    },
  },
  methods: {
    async getProductDetail() {
      try {
        let res = await this.$API.GET_ProductDetail(this.$route.params.id);
        this.productDetail = res.data.Data;
      } catch (e) {
        console.error(e);
      }
    },
    async toBuy() {
      if (this.isSaleOut) return;

      //雙人合盤or宮位解讀(未來) 進入到專屬購買頁面
      if (this.productDetail.Code == "Synastry") {
        this.$router.push("/Synastry");
        return;
      }

      if (this.useType === "gift" && this.giftMode === "memberId") {
        if (!this.isReceiverMemberIdCheak && !this.memberIdErrorMsg) {
          this.memberIdErrorMsg = "請點擊確認按鈕來驗證該會員 ID 是否正確";
          this.scrollToError();
          return;
        }
        if (this.memberIdErrorMsg) {
          this.scrollToError();
          return;
        }
      }

      let orderObj = this.$store.state.orderCreater;
      orderObj.ProductID = this.productDetail.ProductID;
      orderObj.Code = this.productDetail.Code;
      orderObj.Quantity = this.butQuantity || 1;
      orderObj.ReceiverMemberId = null;

      orderObj.Type =
        this.useType === "gift" ? (this.giftMode == "memberId" ? 1 : 2) : 0;

      if (orderObj.Type == 1) {
        //錯誤防呆，正常不會觸發
        if (!this.receiverMemberId)
          return alert("無法取得收禮者資訊，請重新輸入");
        orderObj.ReceiverMemberId = this.receiverMemberId * 1;
      }

      if (orderObj.Type == 0 && this.productDetail.Type == 0) {
        //判斷服務需選對象，禮物模式則不用
        this.$store.commit("updateOrderCreater", orderObj);
        this.$router.push("/store/selectTarget");
        return;
      }

      this.$store.commit("updateOrderCreater", orderObj);
      this.$router.push("/order/checkInfo");
    },
    async checkRevicerMemberId() {
      if (this.isReceiverMemberIdCheak) return;
      try {
        let res = await this.$API.GET_CheckBuyGift(
          this.receiverMemberId * 1,
          this.productDetail.ProductID
        );
        let resObj = res.data.Data;
        console.log(resObj);
        //檢核通過
        if (resObj.Result) {
          this.isReceiverMemberIdCheak = true;
          this.memberIdErrorMsg = "";
        } else {
          this.memberIdErrorMsg = resObj.FailMessage;
          if (this.memberIdErrorMsg.includes("已")) {
            this.$imageNotice({
              imageIdx: 1,
              title: "朋友已經買過囉",
              desc: `您想贈送的對象：(ID:${this.receiverMemberId}) 已經購買過，可以再從星星雜貨店另外挑選禮物喔！`,
              okText: "前往星星雜貨店",
              okEvent: () => {
                this.$router.push("/store");
              },
              cancelText: "返回當前頁面",
            });
          }
        }
      } catch (e) {
        console.error("[Check Revicer MemberId]", e);
        this.memberIdErrorMsg = "網路連線失敗，請稍後再試";
      }
    },
    quantityHandler(event) {
      let value = event.target.value;
      if (value < 0) {
        this.butQuantity = 0;
      } else if (value > 999) {
        this.butQuantity = 999;
      } else {
        this.butQuantity = value;
      }
      this.$forceUpdate();
    },
    quantityAdd(type) {
      if (type === "add") {
        if (this.butQuantity < 999) {
          this.butQuantity++;
        }
      } else {
        if (this.butQuantity > 1) {
          this.butQuantity--;
        }
      }
    },
    scrollToError() {
      setTimeout(() => {
        let errorEl = document.querySelector(".onError");
        if (errorEl) {
          errorEl.scrollIntoView();
          window.scrollBy(0, -150);
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";
#detail {
  padding: 0 23px;
  letter-spacing: 0.08em;
  h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 58px;
    margin-bottom: 24px;
    color: #ab956c;
    padding-left: 120px;
    display: flex;
    align-items: center;
    @include pad {
      font-size: 18px;
      padding-left: 0;
      justify-content: center;
    }
    > img {
      height: 54px;
      @include pad {
        height: 26px;
      }
    }
  }
  .detail_area {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    padding: 72px 76px;
    background: #f0f2f4;
    box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
      4px 4px 25px rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    @include pad {
      flex-direction: column;
      padding: 50px 42px;
      border-radius: 80px;
    }
    .info_area {
      max-width: 250px;
      @include pad {
        width: 100%;
      }
      .info_area_title {
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: $secondary-color-1;
        letter-spacing: 0.08em;
      }
      .info_area_content {
        font-size: 16px;
        line-height: 28px;
        text-align: justify;
        color: #9b9b9b;
        margin-bottom: 72px;
        @include pad {
          margin-bottom: 30px;
        }
      }
      .info_area_otherInfo {
        h3 {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.08em;
          color: #9b9b9b;
        }
        p {
          font-size: 20px;
          line-height: 36px;
          font-weight: 700;
          letter-spacing: 0.08em;
          margin: 0;
          color: $secondary-color-1;
        }
      }
    }
    .pic_area {
      width: 530px;
      padding: 0 38px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include pad {
        display: none;
      }
      & > img {
        width: 100%;
      }
    }
    .buy_area {
      max-width: 250px;
      @include pad {
        width: 100%;
      }
      .buy_area_price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        margin-bottom: 40px;
        margin-top: 24px;
        &::after {
          content: "";
          height: 1px;
          width: 140%;
          position: absolute;
          bottom: -15px;
          left: 0;
          background-color: $secondary-color-2;
        }
        .price {
          display: flex;
          align-items: flex-end;
          font-weight: 700;
          color: $secondary-color-1;

          & > span:nth-of-type(1) {
            font-size: 16px;
          }
          & > span:nth-of-type(2) {
            font-size: 40px;
            line-height: 1;
          }
        }
        .payMethods {
          display: flex;
          > img {
            height: 16px;
            margin-left: 6px;
          }
        }
        &.isGift {
          margin-top: 0;
          &::after {
            display: none;
          }
        }
      }
      .buy_area_btn {
        margin-top: 36px;
        @include btn-default(232px, 56px, $tw_font);
      }
      .buy_area_notify {
        margin-top: 48px;
        .buy_area_notify_title {
          display: flex;
          align-items: center;
          h3 {
            font-size: 20px;
            line-height: 36px;
            color: #9b9b9b;
            padding-left: 14px;
          }
          div {
            width: 32px;
            height: 32px;
            background: #f0f2f4;
            box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
              4px 4px 10px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            @include center;
            span {
              display: inline-block;
              height: 2px;
              width: 16px;
              background-color: $theme-color-2;
            }
          }
        }
        .buy_area_notify_content {
          margin-top: 22px;
          font-size: 16px;
          line-height: 22px;
          text-align: justify;
          color: #9b9b9b;
          font-weight: 700;
        }
      }

      .buy_area__sectionTitle {
        font-size: 16px;
        line-height: 28px;
        display: flex;
        align-content: center;
        color: #17445c;
        img {
          width: 28px;
          height: 28px;
          margin-right: 0;
        }
      }
      //選擇自用或送禮
      .buy_area__useType {
        display: flex;
        flex-direction: column;
        &__switch {
          display: flex;
          width: 100%;
          label {
            width: 50%;
            height: 36px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #798da5;
            color: #868686;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            cursor: pointer;
            input {
              display: none;
            }
            &.active {
              background: #798da5;
              color: #ffffff;
            }
          }
        }
      }

      //選擇送禮模式
      .buy_area__giftMode {
        &__radioGruop {
          display: flex;
          label {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 28px;
            color: #1f1f20;
            cursor: pointer;
            margin-right: 24px;
            input {
              display: none;
            }
            span {
              position: relative;
              padding-left: 30px;
              &:before {
                content: "";
                width: 22px;
                height: 22px;
                border-radius: 50%;
                background: #f0f2f4;
                box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
                  4px 4px 10px rgba(0, 0, 0, 0.2);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
              &:after {
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $theme-color-1;
                position: absolute;
                left: 6px;
                top: 50%;
                transform: translateY(-50%);
                display: none;
              }
            }
            input:checked + span {
              &:after {
                display: block;
              }
            }
          }
        }
        &__notice {
          font-size: 14px;
          line-height: 24px;
          color: #9b9b9b;
          margin-top: 8px;
        }
      }

      // 送禮對象 ID
      .buy_area__receiverMemberId {
        &.onError {
          .buy_area__receiverMemberId__form input {
            border-color: #e2562c;
          }
        }
        &__form {
          height: 28px;
          margin-top: 20px;
          display: flex;
          align-items: center;
          gap: 6px;
          input {
            display: inline-block;
            flex-grow: 1;
            min-width: 140px;
            border-radius: 100px;
            border: 1px solid #ffffff;
            box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15),
              2px 2px 4px 0 #ffffff;
            background-color: #f0f2f4;
            line-height: 22px;
            font-size: 14px;
            letter-spacing: 1.08px;
            color: #17445c;
            padding: 3px 16px;
            &::placeholder {
              color: #d9d9d9;
            }
            &:focus {
              outline: none;
            }
            @include mobile {
              font-size: 14px;
            }
          }
          &__checkBtn {
            flex-shrink: 0;
            @include btn-default(64px, 28px, 14px);
            border-radius: 100px;
            @include mobile {
              @include btn-default(64px, 28px, 14px);
            }
          }
        }
        &__errorMsg {
          max-width: 166px;
          font-size: 14px;
          line-height: 20px;
          color: #e2562c;
          margin-top: 4px;
        }
        &__successMsg {
          font-size: 14px;
          line-height: 20px;
          color: #17445c;
          margin-top: 4px;
        }
      }

      //選擇數量
      .buy_area__quantity {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        margin-bottom: 40px;
        margin-right: auto;
        &__arrow {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          font-size: 30px;
          font-weight: 500;
          background: #f0f2f4;
          @include center;
          cursor: pointer;
          box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
            4px 4px 10px rgba(0, 0, 0, 0.2);
          position: relative;
          user-select: none;
          @include mobile {
            width: 24px;
            height: 24px;
          }
          &.decrease:after,
          &.increase:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 2px;
            width: 12px;
            border-radius: 3px;
            background-color: #9b9b9b;
          }
          &.increase:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 12px;
            width: 2px;
            border-radius: 3px;
            background-color: #9b9b9b;
          }
        }
        .buy_area__quantity__content {
          display: flex;
          align-items: center;
          &__number {
            background: #f0f2f4;
            box-shadow: 2px 2px 4px #ffffff,
              inset 2px 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 20px;
            color: $theme-color-2;
            text-align: center;
            input {
              border: none;
              background: none;
              color: #17445c;
              text-align: center;
              width: 49px;
              height: 28px;
              font-size: 14px;
              line-height: 20px;
              font-weight: 700;
              padding: 4px 12px;
              @include mobile {
                width: 49px;
                height: 28px;
                font-size: 14px;
                line-height: 20px;
                padding: 4px 12px;
              }
              &:focus {
                outline: none;
              }
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
        }
        .buy_area__quantity__unit {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          color: #17445c;
          margin-left: 16px;
          @include mobile {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
